
import React from 'react';
import PostHero from '../components/PostHero'
import PostFooter from '../components/PostFooter'
import PostContent from '../components/PostContent'
import PostOutcome from '../components/PostOutcome'

import banner from '../images/paws_up/paws_up_banner.jpg';
import intro from '../images/paws_up/Paws_up_intro.jpg';
import login from '../images/paws_up/Paws_up_login.jpg';
import form from '../images/paws_up/Paws_up_form.jpg';
import account from '../images/paws_up/Paws_up_account.jpg';
import email from '../images/paws_up/Paws_up_email.jpg';

import techStack from '../images/paws_up/Paws_up_teckstack.jpg';



const PostPawsUp = () => {
    return (
        <div className="post_body">
        <div className="post_container">
            <PostHero 
            image={banner}
            title="Developing Dog Vaccine Reminder Web App" 
                from="From" company="Personal Project"  role1="•  Buildng single page app uisng Vue.js + Vite" role2="• Implementing Firebase for email authentication" role3="• Fetching and storing user data with Firestore" role4="• Creating cron jobs to automate email notifications" role5="• Styling page using TailwindCSS"
            />
            <PostContent classes="" image={intro} title="Project Objective" 
            body="
            As a dog owner, managing my dog's medical history, especially when switching vets, has been a hassle—digging through emails and invoices is no easy task. I can only imagine how much harder it is for owners with multiple dogs. That’s why I built an application to store vaccine records in one place and send email notifications, making it stress-free to stay on top of vaccinations!            " 
            />
            <PostContent classes="flex-row-reverse" image={login} title="Email Authentication" 
            body="
            When brainstorming this project, my priority was to make the user flow as simple as possible. I decided to implement Google Sign-In and a straightforward email sign-in option using Firebase. To ensure authenticity, I added an email verification step to confirm that the email used for registration is verified.
            " 
            />

            <PostContent classes="" image={form} title="Setting up profile" 
            body="
            After signing in, users can now add their dog's information, input the most recent vaccination details, and opt-in for email notifications. To enable this functionality, I used Firestore to securely store the data, which can later be fetched and displayed on the account page.
            " 
            />
             <PostContent classes="flex-row-reverse" image={account} title="Manage information" 
            body="
            Once saved, users are directed to the account page, where they can view and edit their dog's information in one place. The page also displays the due dates for all vaccine types across all their dogs, providing a comprehensive overview on a single screen.
            " 
            // button="View Website"
            // url="https://jihyeonoh12.github.io/note-app/"
            />
            <PostContent classes="" image={email} title="Email Reminder" 
            body="
            Lastly, a daily email reminder cron job runs to check if any dogs with an active email subscription have overdue vaccinations. If any are found, the system sends an email notification listing the vaccines that are due
            .
            " 
            />
           <PostOutcome image={techStack} classes="hidden" title="Future Enhancements" 
        //    body="
        //     For this project, I wanted to expand my knowledge of the Vue.js framework. It was a great experience to explore and utilize a JavaScript framework other than React. Additionally, it was my first time working with Firebase and hosting cron jobs, which required some learning but provided an excellent opportunity to gain hands-on experience with both.            
        //     " 
            body="
            For future development, I plan to add features that allow users to input medication details and create custom vaccine fields. Additionally, I aim to expand the app’s functionality to include options for other pets, such as cats, making it more versatile
            "
            button="Paws Up" url="https://paws-up-a1046.web.app"
            button2="Github" url2="https://github.com/jihyeonoh12/paws_up"
                />
            <PostFooter />
            </div>
        </div>
    )
}



export default PostPawsUp